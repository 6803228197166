import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export default abstract class BaseApi {
  protected axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  get<Res = unknown>(
    ...args: Parameters<AxiosInstance['get']>
  ): Promise<AxiosResponse<Res>> {
    return this.axios.get(...args);
  }

  post<Res = unknown>(
    ...args: Parameters<AxiosInstance['post']>
  ): Promise<AxiosResponse<Res>> {
    return this.axios.post(...args);
  }

  delete<Res = unknown>(
    ...args: Parameters<AxiosInstance['delete']>
  ): Promise<AxiosResponse<Res>> {
    return this.axios.delete(...args);
  }

  getFullUri(config: AxiosRequestConfig): string {
    // Current axios version does not provide an api to get the full uri so we need to add the domain manually
    const LEADING_SLASHES_RE = /^\/+/;

    const url = `${window.location.origin}${this.axios.defaults.baseURL}${(
      config.url as string
    ).replace(LEADING_SLASHES_RE, '')}`;
    return this.axios.getUri({ ...config, url });
  }
}
